.container-shop {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-family: "Poppins", sans-serif;
  min-height: 70vh;

  .shop-training {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;

    @media screen and (max-width: 460px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.h1-comingSoon {
  position: absolute;
  opacity: 1.1;
  top: 40%;
  z-index: 1;
  text-align: center;
  width: 100%;
  font-size: 12vw;
}

.h3-disclaimer-shop {
  color: #f57c00;
  margin-bottom: 15px;
}

.react-router-modal__container {
  position: inherit !important;
  z-index: inherit !important;
}

.react-router-modal__wrapper {
  position: inherit !important;
  z-index: inherit !important;
}

.react-router-modal__backdrop {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 0px !important;
  height: opx !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.react-router-modal__modal {
  position: inherit !important;
  top: 0% !important;
  left: 0% !important;
  transform: none !important;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #777;
}

.react-router-modal__modal-open {
  overflow-y: unset !important;
}

.active-button {
  width: 250px;
  height: 58px;
  border-radius: 10px;
  background-color: #000000 !important;
  background-blend-mode: normal;
  color: #ffffff !important;
  text-transform: initial !important;
  font-size: 18px !important;
  margin-top: 45px !important;
  margin-bottom: 20px !important;
  border-radius: 12px !important;
}

.nonActive-button {
  width: 250px;
  height: 58px;
  border-radius: 10px;
  background-color: #ffffff !important;
  background-blend-mode: normal;
  color: #000000 !important;
  text-transform: initial !important;
  font-size: 18px !important;
  margin-top: 45px !important;
  margin-bottom: 20px !important;
  border-radius: 12px !important;
  border: 1.5px solid !important;
}

.button-both-together {
  display: flex;
  flex-wrap: wrap;
}