.get-the-app-container {
  background-color: #0c120c !important;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 3.2552083333333335vw;
  padding-right: 3.2552083333333335vw;

  .get-the-app-sideimg {
    width: 57.161458333333336vw;
    height: auto;
    padding-left :7.2265625vw;
  }
  .get-app-grid-item-container1 {
    padding-left: 1.3020833333333333vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 40vw;
  }
  .get-app-grid-item-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 60vw;
  }
  .get-app-grid-right-item-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 70%;
  }
  .get-app-grid-right-item-container-title {
    font-size: 3.90625vw;
    margin: 11.067708333333334vw 0px 0px;
    color: white;
  }
  .get-app-grid-right-item-sub-container {
    display: flex;
    justify-content: space-around;
  }
  .get-app-grid-left-img {
    width: 25.651041666666668vw;
    margin-right: 1.953125vw;
  }
  .get-app-grid-right-img {
    width: 25.651041666666668vw;
  }
  .get-app-grid-right-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .get-app-grid-left-container {
    border-top: 1px solid rgb(109, 114, 117);
    width: 52.5390625vw;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .get-app-grid-left-container-title {
    font-size: 3.90625vw;
    margin: 4.752604166666667vw 0px 12.890625vw;
    color: white;
  }
}
