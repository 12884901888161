.container-fillable {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-family: "Poppins", sans-serif;
  min-height: 70vh;
  .wrapper-fillable {
    width: 976.5625vw;
    padding-left: 3.2552083333333335vw;
    padding-right: 3.2552083333333335vw;
    hr {
      opacity: 0.3;
    }
    .org-button {
      height: 2.9947916666666665vw;
      border-radius: 1.3020833333333333vw;
      border: solid 1px;
      background: #ffffff;
      margin: 0.6510416666666666vw;
      cursor: pointer;

      span {
        font-size: 1.0416666666666667vw;
        font-weight: 500;
        line-height: 1.0416666666666667vw;
        text-align: center;
        text-transform: capitalize;
        @media screen and (max-width: 870px) {
          font-size: 12px;
        }
        @media screen and (max-width: 500px) {
          font-size: 8px;
        }
      }
      img {
        padding: 0.3255208333333333vw;
        width: 2.4583333333333333vw !important;
        height: 2.4583333333333333vw !important;
        @media screen and (max-width: 800px) {
          width: 24px !important;
          height: 22px !important;
        }
        @media screen and (max-width: 665px) {
          width: 12px !important;
          height: 13px !important;
        }
      }
    }
    @media screen and (max-width: 684px) {
      .header-of-filabble {
        justify-content: initial !important;
      }
      .courseAssesment-btn-container{
        justify-content: initial !important;
      }
    }

    .time-button {
      height: 2.9947916666666665vw;
      border-radius: 1.3020833333333333vw;
      border: solid 1px;
      background: #ffffff;
      margin: 0.6510416666666666vw;
      cursor: pointer;

      span {
        font-size: 1.0416666666666667vw;
        font-weight: 500;
        line-height: 1.0416666666666667vw;
        text-align: center;
        text-transform: lowercase;
        @media screen and (max-width: 870px) {
          font-size: 12px;
        }
        @media screen and (max-width: 500px) {
          font-size: 8px;
        }
      }
    }
  }
}

.react-router-modal__container {
  position: inherit !important;
  z-index: inherit !important;
}
.react-router-modal__wrapper {
  position: inherit !important;
  z-index: inherit !important;
}
.react-router-modal__backdrop {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 0px !important;
  height: opx !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.react-router-modal__modal {
  position: inherit !important;
  top: 0% !important;
  left: 0% !important;
  transform: none !important;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #777;
}
.react-router-modal__modal-open {
  overflow-y: auto !important;
}
.div-organization {
  display: flex;
  flex-wrap: wrap;
  .MuiButton-root {
    min-width: 4.166666666666667vw !important;
  }
}
.text-title {
  height: 1.3020833333333333vw;
  color: #db5461;
  font-size: 1.8vw;
  font-weight: 400;
  line-height: 1.3020833333333333vw;
  padding-left: 0.6510416666666666vw;
  padding-top: 1.95vw;
  padding-bottom: 1.95vw;
  cursor: context-menu;
  @media screen and (max-width: 870px) {
    font-size: 16px;
    padding-bottom: 11px;
    padding-top: 14px;
  }
  @media screen and (max-width: 500px) {
    padding-bottom: 11px;
    font-size: 12px;
    padding-top: 14px;
  }
}
.identity-line {
  font-size: 1.6276041666666667vw;
  font-weight: 400;
  line-height: 2.2135416666666665vw;
  width: -webkit-fill-available;
  @media screen and (max-width: 870px) {
    font-size: 14px;
  }
  @media screen and (max-width: 500px) {
    font-size: 12px;
    line-height: 4.213542vw;
  }
}
.div-fillable {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0.5vw; */
  padding-top: 3.5vw;
  padding-bottom: 1.5vw;

  img {
    width: 4.5625vw;
    padding-right: 1.6276041666666667vw;
  }
  .fillable-icon {
    width: 4.5135416666666665vw;
    // height: 3.2135416666666665vw;
    margin-left: auto;
  }
}
.duration {
  height: 1.5625vw;
  font-size: 1.1067708333333333vw;
  font-weight: 400;
  line-height: 1.5625vw;
  @media screen and (max-width: 500px) {
    font-size: 7px;
  }
}
.sub-div-identity {
  display: flex;
  align-items: center;
  img {
    width: 3.0833333333333335vw;
    height: 3.0833333333333335vw;
    padding: 0.6510416666666666vw;
  }
  h2 {
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 0.5859375vw;
    border: 1px solid #db5461;
    background: #ffffff;
    width: 2.3671875vw;
    height: 1.38vw;
    color: #db5461;
    font-size: 0.6510416666666666vw;
    font-weight: 400;
    line-height: 0.78125vw;
    text-align: justify;
  }
}
.div-identity-sub {
  display: flex;
}
.main-course-div {
  max-height: 850px;
  overflow-y: unset;
}

.org-button-selected {
  height: 2.9947916666666665vw;
  border-radius: 1.3020833333333333vw !important;
  /* border: solid 0px !important; */
  text-transform: capitalize !important;
  background: black !important;
  margin: 0.6510416666666666vw !important;
  color: #ffffff !important;
  cursor: pointer !important;
  span {
    font-size: 1.0416666666666667vw;
    font-weight: 500;
    line-height: 1.0416666666666667vw;
    text-align: center;
    text-transform: capitalize;
    @media screen and (max-width: 870px) {
      font-size: 12px;
    }
    @media screen and (max-width: 500px) {
      font-size: 8px;
    }
  }
  img {
    padding: 0.3255208333333333vw !important;
    width: 2.4583333333333333vw !important;
    height: 2.4583333333333333vw !important;
    @media screen and (max-width: 800px) {
      width: 24px !important;
      height: 22px !important;
    }
    @media screen and (max-width: 665px) {
      width: 13px !important;
      height: 12px !important;
    }
  }
}
.closeFilter-img {
  width: 3vw !important;
  height: 3vw !important;
}
.loader-class {
  text-align: center;
  margin: auto;
  .MuiCircularProgress-colorPrimary {
    color: #212121;
    margin: 30px;
    width: 2.6041666666666665vw !important;
    height: 2.6041666666666665vw !important;
  }
}
.loader-class-course {
  text-align: center;
  margin-left: auto;
  .MuiCircularProgress-colorPrimary {
    color: #212121;
    margin: 15px;
    width: 2.6041666666666665vw !important;
    height: 2.6041666666666665vw !important;
  }
}
.h3-nodata {
  padding-left: 0.6510416666666666vw;
  font-size: 1.3020833333333333vw;
}
.div-main-filter {
  display: flex;
  flex-wrap: wrap;
}
.filter-button {
  height: 2.9947916666666665vw;
  border-radius: 1.3020833333333333vw !important;
  border: 1px solid #db5461 !important;
  background: #ffffff !important;
  margin-left: auto !important;
  color: #db5461 !important;
  cursor: pointer !important;
  margin-top: 0.6510416666666666vw !important;
  text-transform: capitalize !important;
  span {
    font-size: 1.0416666666666667vw;
    font-weight: 500;
    line-height: 1.0416666666666667vw;
    text-align: center;
    text-transform: capitalize;
    @media screen and (max-width: 800px) {
      font-size: 7px !important;
    }
  }
}

.alpha-list {
  height: auto !important;
  overflow-y: unset !important;
  padding-right: 0px !important;
  position: inherit !important;
  display: flex !important;
}

.alpha-list > div > div > div:first-child {
  display: none !important;
}
.alpha-list > div:first-child {
  width: 98% !important;
  max-height: 53.450520833333336vw;
  min-height: 100vh;
  overflow-y: auto !important;
  padding-right: 12px !important;
}
.alpha-list > div:last-child > div {
  color: black !important;
  font-weight: 400;
  line-height: 17px;
  @media screen and (max-width: 700px) {
    font-size: 10px !important;
  }
}
.alpha-list > div:last-child {
  padding-left: 15px !important;
  position: inherit !important;
}
.ant-tag {
  margin-bottom: 4px;
  margin-right: 4px;
  font-size: 12px;
  line-height: 22px;
}
.alpha-list > div:first-child::-webkit-scrollbar {
  width: 6px;
}

.alpha-list > div:first-child::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alpha-list > div:first-child::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.87);
}
.div-nodata {
  text-align: center;
  padding-top: 90px;
  padding-bottom: 90px;
}
.nodata-img {
  width: 40.552083vw;
}
.add-div {
  text-align: center;
}
.add-div-img {
  height: 14.208vw;
  width: 25.6197vw;
}
.lead-select{
  .MuiOutlinedInput-root {
    width: 65px;
}
}
