.product-modal {
    width: 505px !important;
    height: auto;
    border-radius: 20px;
    background: #ffffff;
    background-blend-mode: normal;

    @media screen and (max-width: 560px) {
        width: 89.75vw !important;
    }
}

.product-modal-test {
    width: 387px;
    height: 52px;
    color: #090909;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: justify;
}

.active-button-modal {
    width: 203px;
    height: 58px;
    border-radius: 10px;
    background-color: #000000 !important;
    background-blend-mode: normal;
    color: #ffffff !important;
    text-transform: initial !important;
    font-size: 18px !important;
    margin-top: 45px !important;
    margin-bottom: 20px !important;
    border-radius: 12px !important;

    @media screen and (max-width: 410px) {
        font-size: 3.8vw !important;
    }
}

.nonActive-button-modal {
    width: 203px;
    height: 58px;
    border-radius: 10px;
    background-color: #ffffff !important;
    background-blend-mode: normal;
    color: #000000 !important;
    text-transform: initial !important;
    font-size: 18px !important;
    margin-top: 45px !important;
    margin-bottom: 20px !important;
    border-radius: 12px !important;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25) !important;

    @media screen and (max-width: 410px) {
        font-size: 3.8vw !important;
    }
}

.button-both-together-modal {
    display: flex;
}