.container1 {
  display: flex;
  flex: 1;
  position: relative;
  .explore-btn-container{
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (min-width: 704px) {
    .explore-btn-container{
      position: absolute;
      top: 120%;
      left: 70%;
    }
  }
}
.centered {
  position: absolute;
  top: 6%;
  left: 2%;
}
.title {
  font-size: 4.55vw;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.subTitle {
  font-size: 2.34vw;
  color: #db5461;
}
.subTitle2 {
  font-size: 2.34vw;
  color: #0C120C;

  // u{
  //   text-decoration-color: #db5461;
  // }
}
.sideText {
  font-size: 3.58vw;
}

.responsive-padding {
  padding-left: 100px;
}
.loader-class-course {
  text-align: center;
  margin-left: auto;
  .MuiCircularProgress-colorPrimary {
    color: #212121;
    margin: 15px;
    width: 2.6041666666666665vw !important;
    height: 2.6041666666666665vw !important;
  }
}

@media screen and (max-width: 900px) {
  .responsive-padding {
    padding-left: 20px;
  }
}
