.textField {
  box-shadow: 0px 2px 35px rgba(44, 44, 44, 0.15);
  border: 1px solid black;
  width: 59.114583333333336vw;
  border-radius: 25px;
  background: #f3f4f5;
  margin-right:  2.6041666666666665vw !important;
  input {
    padding:  0.9765625vw !important;
    outline: none;
    font-size: 0.9765625vw;
  }
  @media (max-width: 600px){
    input {
      padding:  0.9765625vw !important;
      outline: none;
      font-size: 1.9765625vw;
    }
    .img-close-serach{
      width: 2.171875vw;
      height: 2.171875vw;
      cursor: pointer;
    }
  }
  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 28px;
  }
}

.mvp-app-header {
  background-color: rgb(12, 18, 12) !important;
  height: 9.76vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 3.25vw;
}
.mvp-logo-app {
  height: 5.208vw;
  width: 18.6197vw;
}
@media screen and (max-width: 430px) {
  .mvp-app-header {
    height: 9.76vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 3.25vw;
  }
  .mvp-logo-app {
    height: 7.208vw;
    width: 25.6197vw;
  }

}

.home-app-header{
  background-color: rgb(12, 18, 12) !important;
  height: 9.76vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.25vw;
}
@media (min-width: 600px){
.MuiToolbar-regular {
    min-height: 4.166666666666667vw !important;
}
}

@media (max-width: 600px){
  .MuiToolbar-regular {
      min-height: 0px !important;
  }
  }
#asynchronous-demo{
  height: 0.2vw !important;
}
#asynchronous-demo:focus{
  outline: none !important;
}
.container-temp{
  fieldset{
    display: none;
  }
}
.p-tag-searchbar-header{
   color: gray !important;
    font-size: 13px !important;
    cursor: pointer !important;
    display: flex !important;
    flex-wrap: wrap !important;
  p{
    color: gray !important;
    font-size: 13px !important;
    cursor: pointer !important;
  }
  
}

.active-link {
  background-color: #6D7275;
}

.default {
  background-color: #0C120C;
}

.link-container:hover{
  background-color: #6D7275;
}