.radio-group-component-container {
  .radio-group-component-title {
    font-size: 16px;
    @media screen and (max-width: 540px) {
      font-size: 14px;
    }
  }
  .radio-group-component-item {
    font-size: 14px;
    @media screen and (max-width: 540px) {
      font-size: 12px;
    }
  }
  .radio-group-component-detail {
    @media screen and (max-width: 540px) {
      .MuiFormControlLabel-label {
        font-size: 12px;
      }
    }
  }
}
.org-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-icon{
  width: 20px;
}
