.container-privacy {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-family: "Poppins", sans-serif;
    min-height: 70vh;
    .wrapper-policy{
      width: 1000%;
      padding: 20px;
      @media screen and (max-width: 991px) {
        width: 70%;
      }
      @media screen and (max-width: 540px) {
        width: 100%;
      }
     
    }
  }
 
  .react-router-modal__container{
    position: inherit !important;
    z-index: inherit !important;
  }
  .react-router-modal__wrapper {
    position: inherit !important;
    z-index: inherit !important;
}
.react-router-modal__backdrop {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 0px !important;
  height: opx !important;
  background-color: rgba(0,0,0,0.5) !important;
}
.react-router-modal__modal {
  position: inherit !important;
  top: 0% !important;
  left: 0% !important;
  transform: none !important;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #777;
}

.react-router-modal__modal-open{
 overflow-y: scroll !important; 
}