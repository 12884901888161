.grid-container-form {
  height: auto;
  margin-top: 40px;
  justify-content: center !important;
  width: 420px !important;
}

.grid-item-container-form {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  width: auto !important;
  margin-top: 40px !important;
  height: 50px !important;
}
.popUpModel{
  .text-field-helpertext {
    position: absolute;
    text-align: -webkit-center !important;
    width: 100%;
    top: 55px !important;
}
}
