.slick-list {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #f6f6f9;
}

.main-div-slider {
  display: flex;
  flex-wrap: wrap;
}

.particuler-div .slick-slide {
  height: auto;
}

.slick-dots {
  position: initial !important;
  background: #f6f6f9;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.slick-dots li {
  margin: 0px !important;

}

.particuler-div {
  //height: 115% !important;
  margin-bottom: 0px;
  border-radius: 15px;

  h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
}

.slick-arrow {
  display: none !important;
}

// .slick-slide {
//   //height: 50% !important;
// }

.slick-dots li.slick-active button:before {
  font-size: 9px !important;
  color: #db5461 !important;
}

.slick-dots li button:before {
  font-size: 9px !important;
  color: #db5461 !important;
}

.container-shop::-webkit-scrollbar {
  width: 6px;
}

.container-shop::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.container-shop::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.87);
  color: black;
}

.slick-list img {
  margin: 10px !important;
  display: flex !important;
  margin: auto !important;
  height: 210px !important;
  padding-top: 50px !important;
  padding-bottom: 15px !important;
  width: 200px !important;
}

.h2-new {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 0.5859375vw;
  border: 1px solid #db5461;
  background: #ffffff;
  color: #db5461;
  font-size: 10px;
  font-weight: 400;
  line-height: 0.78125vw;
  text-align: justify;
  width: 45px;
  height: 18px;
  border-radius: 10px;
  margin: 15px;
  margin-bottom: -30px;
  z-index: 1;
}

.content-decs {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 10px;
  color: #2d2d2f;
  margin-left: 5px;
}

.MuiRating-iconEmpty {
  .MuiSvgIcon-fontSizeInherit {
    color: #f99d11;
  }
}

.MuiRating-root {
  color: #f99d11 !important;
  margin-left: 5px;
}

.MuiSvgIcon-fontSizeInherit {
  margin: -3px !important;
}

.rating-part {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;

  p {
    margin-left: 5px;
    width: 46px;
    height: 18px;
    color: #bebebe;
    font-size: 15px;
    font-weight: 400;
  }
}

.last-text-line {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-left: 5px;
}

.last-text-line p {
  margin-right: 10px;
  align-self: center;
  font-size: 12px;
}

.last-text-line .display-amount {
  color: #2a8cff;
  font-size: 20px;
}

.last-text-line .price {
  color: #8e9294;
  text-decoration: line-through;
}

.last-text-line .discount-percent {
  color: #ea6363;
  font-weight: 400;
}

.last-text-line .prime-eligible {
  cursor: pointer;
  
}