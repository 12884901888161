.drawer-container {
  z-index: 2147483690;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  top: -2px;
}
.drawer-container-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 321px;
  padding: 23px 5px 14px;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 10000000000000;
  .header-title-left {
    font-size: 24px;
  }
  .header-title-right {
    color: #db5461;
    font-size: 18px;
    cursor: pointer;
  }
  @media screen and (max-width: 540px) {
    padding: 10px 0px 0px;
    .header-title-left {
        font-size: 20px;
      }
      .header-title-right {
        font-size: 15px;
      }
  }
}
.drawer-container-body {
  padding-left: 36px;
  padding-top: 18px;
  .body-title-sortby {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 12px;
  }
  .body-font-16 {
    font-size: 16px;
  }
  @media screen and (max-width: 540px) {
    .body-title-sortby {
        font-size: 14px;
        margin-bottom: 6px;
      }
      .body-font-16 {
        font-size: 12px;
      }
  }
}

.drawer-container-devider {
  margin-left: 39px;
  margin-right: 39px;
}

.drawer-container-footer {
  position: sticky;
  bottom: 1px;
  padding-left: 35px;
  padding-top: 23px;
  padding-bottom: 33px;
  background-color: white;

  .drawer-container-footer-btn {
    background-color: rgb(12, 18, 12);
    color: white;
    width: 264px;
    height: 42px;
  }
}
