*,
*::before,
*::after {
  // padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

main {
  overflow: hidden;
}

.header {
  .container-temp {
    list-style: none;
    list-style-type: none;
    text-decoration: none;
  }
}

a,
button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  text-transform: unset;
  text-decoration: none;
}

// Elements
.section {
  margin: 0 auto;
  padding: 6rem 0 1rem;
}

.container-temp {
  height: 9.765625vw;
  margin: 0 auto;
  padding: 0 1.25rem;
}

.brand {
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: var(--color-green-300);
}

// Header
.header {
  position: static;
  left: 0;
  top: 0;
  width: 100%;
  height: 9.765625vw;
  z-index: 10;
  margin: 0 auto;
  background-color: #0c120c;
  box-shadow: var(--shadow-medium);
}

.is-offline-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  color: #db5161;
  margin: auto;
  p {
    font-size: 20px;
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // column-gap: 1rem;
  width: 100%;
  height: 9.765625vw;
  margin: 0 auto;
}

.menu {
  position: absolute;
  top: 0;
  left: -100%;
  height: auto;
  z-index: 10;
  overflow: hidden;
  background-color: #0c120c;
  box-shadow: var(--shadow-medium);
  transition: all 0.45s ease-in-out;

  &.is-active {
    top: 0;
    left: 0;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    margin: 1.25rem;
  }

  &-link {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    color: var(--color-black);
    transition: all 0.3s ease;
  }

  &-block {
    display: inline-block;
    font-family: inherit;
    font-size: var(--display-100);
    font-weight: 500;
    line-height: 1.25;
    user-select: none;
    text-align: center;
    white-space: nowrap;
    margin-left: auto;
    color: var(--color-white);
    box-shadow: var(--shadow-medium);
    transition: all 0.3s ease-in-out;
    height: auto !important;
  }

  // Responsive Media Query
  @media only screen and (min-width: 852px) {
    position: relative;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin-left: auto;
    background: none;
    box-shadow: none;

    &-inner {
      display: flex;
      flex-direction: row;
      // column-gap: 1.75rem;
      margin: 0 auto;
    }

    &-link {
      text-transform: capitalize;
    }

    &-block {
      margin-left: 2rem;
    }
  }
  .menu-inner{
    list-style: none;
  }
}

.burger {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  order: -1;
  width: 1.75rem;
  width: 7vw;
  height: 6vw;
  border: none;
  outline: none;
  visibility: visible;
  color: white;
  cursor: pointer;

  &-line {
    display: block;
    cursor: pointer;
    width: 100%;
    height: 2px;
    height: 0.13020833333333334vw;
    margin: 1.390625vw;
    transform: rotate(0deg);
    background-color: white;
    transition: all 0.3s ease-in-out;
  }

  @media only screen and (min-width: 852px) {
    display: none;
    visibility: hidden;
  }
}

.burger-close {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  background-color: rgb(0 0 0 / 26%);
  transition: all 0.3s ease-in-out;

  &.is-active {
    display: block;
    opacity: 1;
    visibility: visible;
    background-color: rgb(0 0 0 / 26%) !important;
  }
  @media (min-width: 768px) {
    background-color: transparent;
  }
}

.link-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 3.3vw;
  height: 3.1484375vw;
  padding-left: 15px;
  border-radius: 0.5208333333333334vw;
  background-color: rgb(109, 114, 117);
  @media (max-width: 910px) {
    margin-right: 1.8vw;
  }
  @media (max-width: 851px) {
    padding: 15px;
  }
}

.img-1 {
  width: 2.17188vw;
}
.img-2 {
  height: 1.953125vw;
  width: 3.953125vw;
}

@media screen and (max-width: 487px) {
  .img-1 {
    width: 5.171875vw;
  }
  .img-2 {
    height: 4.953125vw;
    width: 4.953125vw;
  }
}

.menu-block {
  margin-left: auto;
}

.MuiPopover-root {
  .MuiPaper-elevation8 {
    li:not(.phoneNumberSelect){
    background: #fff !important;
    border: 1px solid #d1d7dc !important;
    left: 38% !important;
    margin-top: 0.4rem !important;
    top: 10% !important;
    transform-origin: top !important;
    z-index: 1000 !important;
    overflow: auto !important;
    width: 50vw;
    }
  }
}

.menu-item::marker{
  display: none;
}
.menu-item{
  margin-right: 10;
}
