.fillableForm-Grid {
  flex-grow: 0 !important;
  max-width: inherit !important;
  flex-basis: 33.333333%;
  width: 100% !important;
  text-align: -webkit-center;
  //  @media screen and (max-width: 1065px) {
  //   margin-left: 10px !important;
  //   margin-right: 10px !important;
  // }
   @media screen and (max-width: 721px) {
    flex-basis: 100% !important;
  }
  input {
    border-radius: 6px !important;
    border: solid 1px !important;
    background: #ffffff !important;
    box-shadow: 0px 10px 35px rgb(44 44 44 / 15%) !important;
  }
  input:placeholder-shown {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 1px;
  }
  .submit-button {
    width: 100%;
    height: 62px;
    // border: dashed 1px #ffffff;
    background-color: black;

    display: flex;
    justify-content: space-evenly;
    border-radius: 12px;
    cursor: pointer;
    .loader {
      width: 10px;
      height: 10px;
      color: white;
      font-size: 10px;
    }
    span {
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  label {
    text-align: initial;
  }
  .courseAssesment-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .courseAssesment-btn {
    background-color: #0c120c !important;
    color: white !important;
    width: 300px;
    height: 50px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid !important;
  }
  p:not(.missionName, .instructorName, .dateOfTraining, .age, .name, .date, .phone, .city, .state, .zipCode, .officeSymbol, .subject, .signatureBlockname, .rankOrBranch, .rankTitle, .email, .streetAddress, .memorandum, .organizationName, .addFields-textareaaddF,.assessMentForm) {
    margin-top: 105px !important;
  }
}

.add-field-title-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 31px !important;
  .add-field-title-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // padding-left: 93px;
    .title {
      font-size: 20px;
      font-weight: 400;
      padding-right: 20px;
    }
  }
}
.InstructorNameField {
  padding: 0 !important;
  margin: 60px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
}
.mobile-no{
    min-width: 225px !important;
    min-height: 100px;
    margin-bottom: 0px;
    border: 0px solid white;
}
.h4-phoneNumber{
    width: 308px;
    text-align: justify;
    margin: 10px;
    /* width: 155px; */
    height: 27px;
    /* font-family: "Wavehaus-95SemiBold"; */
    font-size: 22px;
    font-weight: 400;
    line-height: 27px;
}