.course-details-offline-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-family: "Poppins", sans-serif;
    min-height: 70vh;
    .wrapper-training {
      width: 976.5625vw;
      padding-left: 3.2552083333333335vw;
      padding-right: 3.2552083333333335vw;
      hr {
        opacity: 0.3;
      }
      .org-button {
        height: 2.9947916666666665vw;
        border-radius: 1.3020833333333333vw;
        border: solid 1px;
        background: #ffffff;
        margin: 0.6510416666666666vw;
        cursor: pointer;
  
        span {
          font-size: 1.0416666666666667vw;
          font-weight: 500;
          line-height: 1.0416666666666667vw;
          text-align: center;
          text-transform: capitalize;
          @media screen and (max-width: 870px) {
            font-size: 12px;
          }
          @media screen and (max-width: 500px) {
            font-size: 8px;
          }
        }
        img {
          padding: 0.3255208333333333vw;
          width: 2.4583333333333333vw !important;
          height: 2.4583333333333333vw !important;
          @media screen and (max-width: 800px) {
            width: 24px !important;
            height: 22px !important;
          }
          @media screen and (max-width: 665px) {
            width: 12px !important;
            height: 13px !important;
          }
        }
      }
      .time-button {
        height: 2.9947916666666665vw;
        border-radius: 1.3020833333333333vw;
        border: solid 1px;
        background: #ffffff;
        margin: 0.6510416666666666vw;
        cursor: pointer;
  
        span {
          font-size: 1.0416666666666667vw;
          font-weight: 500;
          line-height: 1.0416666666666667vw;
          text-align: center;
          text-transform: lowercase;
          @media screen and (max-width: 870px) {
            font-size: 12px;
          }
          @media screen and (max-width: 500px) {
            font-size: 8px;
          }
        }
      }
    }
  }
  .pading-course-related{
    padding-right: 20px;
    padding-left: 20px;
  }