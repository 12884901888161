.MuiTabs-indicator {
  background-color: white;
  display: none;
}

.MuiTabs-flexContainer {
  justify-content: space-evenly;
}

.course-detail-header-container {
  .MuiTabs-flexContainer {
    display: flex;
    flex-wrap: wrap;
    background-color: #0c120c;
    justify-content: flex-start;
  }
  .header-title-style {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 16px;
    min-width: 250px !important;
  }
  @media screen and (max-width: 768px) {
    .header-title-style {
      padding-left: 25px;
      padding-right: 25px;
      font-size: 12px;
    }
  }
}
