.center-data {
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";
}
.h3-disclaimer-training {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  color: #f57c00;
}
.container-center-data {
  display: "flex";
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";
  height: auto;
  min-height: 70vh;
}
.course-details-container {
  display: "flex";
  flex-direction: "column";
  height: auto;
  min-height: 70vh;
  .course-header-title-container {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .course-header-title-container-ext {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .course-product-container {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
  }
  .loader-container {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader-class {
      text-align: center;
      margin: auto;
      .MuiCircularProgress-colorPrimary {
        color: #212121;
        margin: 30px;
        width: 2.6041666666666665vw !important;
        height: 2.6041666666666665vw !important;
      }
    }
  }
  .tab-container {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 70vh;
  }
  .pading-course {
    padding: 0px 20px 20px 50px;
    a:link {
      color: blue;
      background-color: transparent;
      text-decoration: underline;
    }
    a:visited {
      color: blue;
      background-color: transparent;
      text-decoration: underline;
    }
    a:hover {
      color: purple;
      background-color: transparent;
      text-decoration: underline;
    }
  }
  .pading-course-related {
    padding: 20px 20px 20px 20px;
    min-height: 70vh;
  }
}

.container-progress-spinner {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-offline-container{
  min-height: 70vh;
  height: auto;
  padding-top: 20px;
  text-align: center;
  // color: #db5161;
}
