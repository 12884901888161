@media screen and (max-width: 728px) {
    .header-of-filabble {
      justify-content: initial !important;
    }
    .courseAssesment-btn-container{
      justify-content: initial !important;
    }
  }
  // .addFields-textarea{
  //   .MuiInputBase-multiline {
  //     border: none !important;
  //     width: 92% !important;
  //   }
  // }