.courseAssesment-btn-container-ext {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.courseAssesment-btn-container {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.courseAssesment-btn {
  background-color: #0c120c !important;
  color: white !important;
  width: 300px;
  height: 50px;
}
