.container-contact {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-family: "Poppins", sans-serif;
  min-height: 70vh;
  .banner {
    width: 50%;
    position: relative;
    padding-top: 100px;
    text-align: center;
    @media screen and (max-width: 991px) {
      width: 30%;
    }
    @media screen and (max-width: 540px) {
      width: 100%;
      padding-top: 20px;
    }
    .logo {
      @media screen and (max-width: 768px) {
        left: 30px;
      }

      top: 50px;
      left: 50px;
      position: absolute;
      object-fit: fill;
      z-index: 99;
      width: clamp(100px, 70%, 240px);
    }
    .banner-img {
      width: 37.109375vw !important;
      height: 38.671875vw !important;
      object-fit: cover;
      position: relative;
      @media screen and (max-width: 540px) {
        height: 150px;
      }
    }
  }
  .wrapper-contact {
    width: 50%;
    @media screen and (max-width: 991px) {
      width: 70%;
    }
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    .contact-form {
      position: relative;
      margin: 10px;
    }

    .contact-main-form {
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      width: 70%;
      margin: 0 auto;
      padding-top: 70px;
      .MuiFormControl-root {
        padding: 5px 0;
        width: 100%;
        .MuiOutlinedInput-root {
          width: 100% !important;
        }
      }
      input {
        border-radius: 5px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 2px 35px rgba(44, 44, 44, 0.15);
        border: 1px solid black;
      }
      .input-lable {
        color: #212121;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }
      .Contact-Us {
        height: 36px;
        font-family: "Wavehaus-128Bold";
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        height: 36px;
        @media screen and (max-width: 768px) {
          font-size: 32px;
        }
      }
    }
    .submit-button {
      width: 100%;
      height: 62px;
      // border: dashed 1px #ffffff;
      background-color: black;

      display: flex;
      justify-content: space-evenly;
      border-radius: 12px;
      cursor: pointer;
      .loader {
        width: 10px;
        height: 10px;
        color: white;
        font-size: 10px;
      }
      span {
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .divider-section {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      p {
        color: grey;
        font-size: 15px;
      }
      .divider {
        width: 43%;
        color: black;
      }
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #000 !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
.privacy-policy {
  width: 57px;
  height: 21px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.leave-msg {
  height: 64px;
  font-family: "Wavehaus-95SemiBold";
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  //width: 430px;
}
.char-250 {
  position: absolute;
  margin-top: -32px;
  margin-right: unset;
  margin-left: 100px;
  width: 218px;
  height: 19px;
  color: #6d7278;
  font-family: "Wavehaus-66Book";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: right;
  letter-spacing: 1px;
}
.react-router-modal__container {
  position: inherit !important;
  z-index: inherit !important;
}
.react-router-modal__wrapper {
  position: inherit !important;
  z-index: inherit !important;
}
.react-router-modal__backdrop {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 0px !important;
  height: opx !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.react-router-modal__modal {
  position: inherit !important;
  top: 0% !important;
  left: 0% !important;
  transform: none !important;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #777;
  height: 100vh !important;
  overflow-y: auto !important;
}
.MuiInputBase-multiline {
  padding: 6px 0 7px;
  border-radius: 5px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 35px rgba(44, 44, 44, 0.15);
  border: 1px solid black;
}
.main {
  position: relative;
  display: inline-block;
  width: 100%;
}

.main span {
  color: #6d7278;
  position: absolute;
  bottom: 30px;
  right: 10px;
  width: 100%;
}
.div-btn-submit {
  padding: 30px;
}
.react-router-modal__modal-open {
  overflow-y: scroll !important;
}
.textareatest {
  .MuiOutlinedInput-notchedOutline {
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    margin: 0;
    padding: 0 8px;
    overflow: hidden;
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-radius: inherit;
    pointer-events: none;
  }
}
