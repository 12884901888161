.footer-toolbar-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 325px;
}

@media (min-width: 300px) {
  .footer-toolbar-one {
    margin-left: 0px;
  }
}

.footer-grid-container-ext {
  background-color: #0C120C !important;
  height: auto;
  display: flex;
  align-items: space-between;
  justify-content: center;
  padding: 3.25vw;
  flex-direction: row;
  flex-wrap: wrap;
}
.mvp-footer-logo-app {
  height: 5.2vw;
  width: 34.83vw;
}

.footer-grid-item {
  flex: 1;
  flex-wrap: wrap;
}

@media screen and (max-width: 800px) {
  .mvp-footer-logo-app {
    height: 14.2vw;
    width: 85.83vw;
  }
  .footer-grid-item {
    padding-top: 10px;
  }
}
.footer-content-nav{
  
    font-size: 1.0416666666666667vw !important;
    /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
    font-weight: 400 !important;
    line-height: 1.5 !important;
    letter-spacing: 0.00938em;
    cursor: pointer;
    color: white;
    @media (max-width: 900px) {
      font-size: 10px !important;
    }
}