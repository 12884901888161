.remain-char {
  color: #6d7278;
  position: absolute;
  bottom: 112px;
  left: 110px;
  width: 70%;
  font-size: 12px;
}
// .remain-Char-add-field{
//   color: #6d7278;
//   position: absolute;
//   bottom: 112px;
//   left: 84px;
//   width: 70%;
//   font-size: 12px;
// }
.custom-textfield-placeholder {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
}
.course-header-title {
  color: #db5461;
  font-family: "Wavehaus-128Bold";
  font-size: 26px;
  font-weight: 400;
  text-align: justify;
  text-decoration: underline;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.p-v-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.text-field-helpertext {
  position: absolute;
  text-align: -webkit-center !important;
  width: 100%;
  top: 92px !important;
}

.textfield-container {
  margin-left: 23px;
  margin-right: 15px;
  .MuiInputBase-multiline {
    border: none !important;
  }
}

.cancel-icon {
  position: absolute;
  margin-left: -25px;
  margin-top: 5px;
}
.field-number {
  padding-right: 11px;
  font-size: 22px;
  font-weight: 400;
  z-index: 1;
  margin-left: -30px;
  @media screen and (max-width: 1065px) {
    margin-left: -30px;
  }
  position: absolute;
}
.organizationName:not(label),
.memorandum:not(label),
.streetAddress:not(label) {
  margin-top: 75px !important;
}
.addFields-textareaaddF {
  margin-top: 45px !important;
}
.h6-asseesment {
  position: initial;
  margin-top: -90;
  color: #f44336;
  // margin: 0;
  font-size: 0.75rem;
  // margin-top: 3px;
  // text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.multiline-textfield {
  .MuiOutlinedInput-multiline {
    .MuiOutlinedInput-inputMultiline {
      max-height: 79px;
      margin-bottom: 10px;
    }
  }
}
